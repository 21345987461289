import React from 'react';
import './LoadingOverlay.css';
import { useTranslation } from 'react-i18next';

export const LoadingOverlay = ({ active, percentage }: {active: boolean, percentage?: number}) => {
    const { t } = useTranslation();
    if (!active) return null;
  return (
      <div className="loading-overlay">
          <div className="loading-icon"></div>
          {percentage !== undefined && (
            <div className="loading-text">{percentage}% {t('完了')}...</div>
          )}
      </div>
  )
}
