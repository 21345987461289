import './MeetingList.css'

import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite'

import { MeetingItem } from './MeetingItem'
import { Meeting } from '../../store/model/Meeting'
import UserInfo from '../../store/UserInfo'
import { useTranslation } from 'react-i18next'
import { getNotifications, NotificationFirebaseData } from '../../api/MeetingFirebaseApi';

export const MeetingList = observer(() => {
  const { t } = useTranslation()
  const [notifications, setNotifications] = useState<NotificationFirebaseData[]>([]);

  useEffect(() => {
    (async() => {
      await getNotifications(UserInfo.id, setNotifications);
    })()
  }, [])

  useEffect(() => {
    // sync all meetings
    UserInfo.meetings?.startSync()
    return () => {
      UserInfo.meetings?.stopSync()
    }
  }, [])

  const handleItemDeleted = useCallback((item: Meeting) => {
    // no need to do anything here since data are synced by Firestore
  }, [])

  return (
    <div className='meeting-list container p-4 p-md-4 p-lg-5'>
      <div className='row justify-content-between' style={{ marginBottom: 50 }}>
        <div className='col-auto mb-5'>
          <img src='donutai_1_0_logo.png' style={{ width: '270px', maxHeight: '75px' }} alt="Donut AI" />
        </div>
      </div>
      {UserInfo.meetings?.loaded ? (
        UserInfo.meetings?.dataArray.length ? (
          <div className='row'>
            {UserInfo.meetings?.dataArray.map((meeting) => (
              <MeetingItem key={meeting.id} meeting={meeting} onDelete={handleItemDeleted} notifications={notifications.filter(n => n.meeting === meeting.id)} />
            ))}
          </div>
        ) : (
          <div className='row py-5'>
            <div className='col text-secondary text-center'>{t('ミーティングがありません。')}</div>
          </div>
        )
      ) : (
        <div className='row py-5'>
          <div className='col text-center'>{t('処理中…')}</div>
        </div>
      )}
    </div>
  )
})
