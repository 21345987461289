import { useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { MINUTE, initialSummary, initialMinutes, RealtimeMinute } from '../store/model/Meeting';

export const useMinutes = (): { getMarkdown: (item: RealtimeMinute) => string, isInitialMinutes: (items: RealtimeMinute[]) => boolean } => {
  const { t } = useTranslation();

  const getMarkdown = useCallback((item: RealtimeMinute) => {
    let showTexts = item.markdown;

    if (item.type === MINUTE.summary && item.markdown !== initialSummary) {
      showTexts = '';
      const sections = ['summary', 'next_step'];
      const translations = ['議論内容', 'ネクストアクション提案'];

      sections.forEach((section, index) => {
        const content = item[section as keyof typeof item];
        if (Array.isArray(content) && content.length > 0) {
          showTexts += `## ${t(translations[index])}\n`;
          showTexts += `- ${content.join('\n- ')}\n`;
        }
      });

      if (showTexts === '') {
        showTexts = item.markdown;
      }
    }

    if (item.type === MINUTE.agenda && item.markdown !== initialMinutes) {
      showTexts = '';
      const sections = ['minutes', 'concerns', 'pending', 'decisions', 'next'];
      const translations = ['議事録', '懸念事項', '保留事項', '決定事項', '宿題事項'];

      sections.forEach((section, index) => {
        const content = item[section as keyof typeof item];
        if (Array.isArray(content) && content.length > 0) {
          showTexts += `## ${t(translations[index])}\n`;
          showTexts += `- ${content.join('\n- ')}\n`;
        }
      });
    }
    return showTexts;
  }, [t]);

  const isInitialMinutes = useCallback((items: RealtimeMinute[]) => {
    let isInitialSummary = false;
    let isInitialMinute = false;
    for (const item of items) {
      if (item.type === MINUTE.summary && item.markdown === initialSummary) {
        isInitialSummary = true;
      }
      if (item.type === MINUTE.agenda && item.markdown === initialMinutes) {
        isInitialMinute = true;
      }
      if (isInitialSummary && isInitialMinute) {
        return true;
      }
    }
    return false;
  }, []);

  return { getMarkdown, isInitialMinutes };
}
