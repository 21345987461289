import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import { CardItem, type CardTokenResult } from './CardItem'
import UserInfo from '../../../store/UserInfo'
import { payjp } from '../../../api/Payjp'

type CardInputFormProps = JSX.IntrinsicElements['form'] & {
  payJPElements: any,
  setCardToken: (cardState: CardTokenResult) => void,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CardInputForm = ({ payJPElements, setCardToken }: CardInputFormProps) => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const [lastCardInfo, setLastCardInfo] = useState<CardTokenResult | null>(null)

  useEffect(() => {
    UserInfo
      .getLastUsedCardInfo()
      .then(([isOk, data]) => {
        if (isOk) {
          setLastCardInfo(data)
        }
      })
  }, [])

  // TODO TODO TODO TODO
  // at this point I don't know if the token generated here
  // has the expiry date. payjp documentation never mentions this.
  //
  // If there isn't, there's a possibility that you can basically
  // spam the token generation and get into a rate limit.
  const submit = async () => {
    const element = payJPElements.getElement('cardNumber')
    if (element) {
      const tokenResult = await payjp.createToken(element, { card: { name } })
      if (tokenResult.error) {
        // TODO Error Handling
      }

      setCardToken({
        token: tokenResult.id,
        lastDigit: tokenResult.card.last4,
        expiry: tokenResult.card.exp_month,
        name: tokenResult.card.name,
      })
    }
  }

  useEffect(() => {
    if (payJPElements) {
      let cardNumber = payJPElements.getElement('cardNumber')
      let cardExpiry = payJPElements.getElement('cardExpiry')
      let cardCvc = payJPElements.getElement('cardCvc')
      if (!cardNumber) {
        cardNumber = payJPElements.create('cardNumber')
      }
      if (!cardExpiry) {
        cardExpiry = payJPElements.create('cardExpiry')
      }
      if (!cardCvc) {
        cardCvc = payJPElements.create('cardCvc')
      }
      const option = { style: { base: { lineHeight: 1.5 } } }

      setTimeout(() => {
        cardNumber.update({...option, placeholder: t('カード番号')})
        cardExpiry.update({...option, placeholder: t('月 / 年')})
        cardCvc.update(option)

        cardNumber.mount('#card-digit')
        cardExpiry.mount('#card-expiry')
        cardCvc.mount('#card-cvc')
      }, 0)

      return () => {
        cardNumber.unmount()
        cardExpiry.unmount()
        cardCvc.unmount()
      }
    }
  }, [payJPElements, t])

  return (
    <div className='container'>
      {lastCardInfo != null ? (
        <>
          <h3 className='my-4'>{t('既存の決済情報を使う')}</h3>
          <div className='row g-2'>
            <div className='col-md-7 col-sm-12 col-lg-6'>
              <CardItem card={lastCardInfo!}>
                <button
                  onClick={() => setCardToken(lastCardInfo)}
                  className='btn btn-secondary'>
                  {t('確定する')}
                </button>
              </CardItem>
            </div>
          </div>
        </>
      ) : null}
      <h3 className='my-4'>{t('決済情報の入力')}</h3>
      <div className='row g-2'>
        <div className='col-md-6 col-sm-12'>
          <label htmlFor='card-digit' className='form-label'>
            {t('カード番号')}
          </label>
          <div
            id='card-digit'
            className='form-control'
          ></div>
        </div>
        <div className='w-100'></div>
        <div className='col-md-6 col-sm-12'>
          <label htmlFor='card-name' className='form-label'>
            {t('カード名義')}
          </label>
          <input
            id='card-name'
            className='form-control'
            value={name}
            type='text'
            onChange={(e) => setName(e.currentTarget.value)}
            placeholder={t('カード名義')}
          />
        </div>
        <div className='w-100'></div>
        <div className='col-md-3 col-sm-6'>
          <label htmlFor='card-expiry' className='form-label'>
            {t('有効期限')}
          </label>
          <div className='input-group'>
            <div
              id='card-expiry'
              className='form-control'
            ></div>
          </div>
        </div>

        <div className='col-md-3 col-sm-6'>
          <label htmlFor='card-cvc' className='form-label'>
            CVC
          </label>
          <div
            id='card-cvc'
            className='form-control'
          ></div>
        </div>

        <div className='w-100'></div>

        <div className='col-6'>
          <button className='btn btn-primary' onClick={submit}>
            {t('確定')}
          </button>
        </div>
      </div>
    </div>
  )
}
