import { makeAutoObservable } from 'mobx'
import { getMeetingMessages, updateMeetingMessageText, deleteMeetingMessage } from '../../api/MeetingFirebaseApi'
import { type ILanguages } from '../../constant/Languages'
import UserInfo from "../UserInfo";
import i18next from 'i18next';

const formatDate = (date: Date) => {
  return `${date.getFullYear()} 年 ${date.getMonth() + 1} 月 ${date.getDate()} 日`
}

function timestampToDate(something: any): Date {
  if (something instanceof Date) {
    return something
  } else if (typeof something === 'object' && something != null && 'seconds' in something) {
    return new Date(something.seconds * 1000)
  } else if (typeof something === 'string') {
    return new Date(something)
  }

  throw new Error("This cannot be considered time")
}

export enum MINUTE {
  'summary' = 'summary',
  'agenda' = 'agenda',
}

export const summaryAgenda: string = '全体要約';
export const initialSummary: string = '議事内容を定期的に要約します。';
export const initialMinutes: string = '議題に沿った議事録を作成します。';

export type ChatMessage = {
  id: string
  userId: string | number
  userName?: string
  language: string
  messages: { [lang: string]: string }
  createdAt?: Date
  updatedAt?: Date
  speakerId?: string
  audioUrl?: string
}

export type Participant = {
  clientId: string
  id: string
  isAddedByUser: boolean
  name: string
  state: string
}

export type Speaker = {
  id: string
  userId: string | null
  username: string
}

export interface RealtimeMinute {
  agenda: string;
  type: MINUTE;
  markdown: string;
  minutes?: string[];
  concerns?: string[];
  pending?: string[];
  decisions?: string[];
  next?: string[];
}

export enum SummaryStatus {
  NONE = 'none',
  PROCESSING = 'process',
  READY = 'ready',
}

const PREVIEW_MESSAGE_AMOUNT = 10

export class Meeting {
  /**
   * Get all messages in a specified language. If no language is specified get all messages in all languages.
   */
  static getAllMessages = (meeting: Meeting, language?: string) => {
    const messages = (meeting.messages || [])
    const participants = (meeting.participants || [])
    const speakers = (meeting.speakers || [])
    const list: { id: string, user: string, messages: { [lang: string]: string }, createdAt?: Date | undefined, audioUrl?: string | undefined }[] = []

    // 区切り記号
    const separateString = '/';

    messages.forEach((msg) => {
      if (msg.messages && (!language || msg.messages[language])) {
        const id = msg.id;
        const createdAt = msg.createdAt;
        const messages = language ? { [language]: msg.messages[language] } : { ...msg.messages };
        const sortedMessages = Object.keys(messages)
          .sort((a, b) => {
            if (a.split("-")[0] === i18next.language.split("-")[0]) return -1; // 指定した言語を最上位に
            if (b.split("-")[0] === i18next.language.split("-")[0]) return 1;  // 指定した言語を最上位に
            return a.localeCompare(b);    // 他は通常のアルファベット順
          })
          .reduce<{ [lang: string]: string }>((acc, key) => {
            acc[key] = messages[key];
            return acc;
          }, {});

        const audioUrl = msg.audioUrl;

        const speakerId = msg.speakerId
        const speakerIdx = speakers.findIndex(s => s.id === speakerId);
        const speaker = speakers[speakerIdx];

        // 自身のメッセージかどうか確認
        const isMine = UserInfo.displayName && UserInfo.displayName.toString() === msg.userName;
        // システムメッセージかどうか確認
        const isSystem = !msg.userId;
        // 話者分離情報が必要か（isAddedByUser が true の参加者情報が存在するか）確認
        const isAddedUserExist = participants.findIndex(p => p.isAddedByUser && p.clientId === msg.userId) !== -1;

        // デフォルトの表示名
        let defaultDisplayName = msg.userName;
        // STTによって割り振られたラベル
        let speakerLabel = msg.speakerId?.split(separateString)[1];
        // 入力された参加者名
        let speakerName = speaker?.username;

        // 表示名を決定する
        let user = defaultDisplayName ?? '';
        // 話者分離情報が必要な場合
        if(isAddedUserExist){
          // 話者分離ラベルが割り振られている場合
          if(speakerLabel){
            user = speakerLabel;
          }
          // 参加者名が割り振られている場合
          if(speakerName){
            user = speakerName;
          }
          // 他の人のメッセージの場合
          if(!isSystem && !isMine){
            user = `${defaultDisplayName}${separateString}${user}`;
          }
        }
        // 自分自身のメッセージで話者分離情報が不要な場合
        if(isMine && !isAddedUserExist){
          user = UserInfo.displayName ?? user;
        }
        if(!user || user === ''){
          user = UserInfo.displayName
        }
        list.push({
          id,
          user,
          messages: sortedMessages,
          createdAt,
          audioUrl,
        });
      }
    })

    return list
  }

  id: string
  owner?: string | number
  title: string
  state: string
  token?: string
  startTime?: string
  users?: string[]
  messages?: ChatMessage[]
  languages?: ILanguages[]
  summaryStatus: SummaryStatus
  summary?: string
  beginAt?: Date
  endAt?:   Date
  scheduledBeginAt?: Date
  scheduledEndAt?:   Date
  allocatedTime?: number // in seconds.
  createdAt: string
  updatedAt: string
  additionalDocument?: string
  realtimeMinutes?: RealtimeMinute[]
  participants?: Participant[]
  speakers?: Speaker[]
  fullAudioUrl?: string

  private fetching = false
  // private setFetching = (fetching = true) => {
  //   this.fetching = fetching
  // }

  private fullyFetched = false

  // Returns in Seconds.
  get timeElapsed(): number | null {
    if (this.beginAt == null || this.endAt == null) {
      return null
    }
    return (this.endAt.getTime() - this.beginAt.getTime()) / 1000
  }

  constructor(id: string, data: any) {
    this.id = id
    this.owner = data.owner
    this.title = data.title
    this.state = data.state

    this.summaryStatus = data.summaryStatus || SummaryStatus.NONE
    this.summary = data.summary

    this.token = data.token || ''
    this.startTime = data.startTime || ''
    this.allocatedTime = data.allocatedTime || 0

    // computed values
    // this.users = data.users
    // this.messages = data.messages
    // this.languages = data.languages

    const createdAt = new Date(data.createdAt.seconds * 1000)
    const updatedAt = new Date(data.updatedAt.seconds * 1000)
    if (data.beginAt) {
      this.beginAt = timestampToDate(data.beginAt)
    }
    if (data.endAt) {
      this.endAt = timestampToDate(data.endAt)
    }

    if (data.scheduledBeginAt) {
      this.scheduledBeginAt = new Date(data.scheduledBeginAt);
    }
    if (data.scheduledEndAt) {
      this.scheduledEndAt = new Date(data.scheduledEndAt);
    }

    this.createdAt = formatDate(createdAt)
    this.updatedAt = formatDate(updatedAt)

    if(data.speakers){
      this.speakers = data.speakers;
    }

    if(data.participants){
      this.participants = data.participants;
    }

    if(data.additionalDocument){
      this.additionalDocument = data.additionalDocument;
    }

    if(data.realtimeMinutes){
      this.realtimeMinutes = data.realtimeMinutes.filter((r: { markdown: string; }) => r.markdown !== initialSummary && r.markdown !== initialMinutes);
      this.summaryStatus = SummaryStatus.READY
    }
    if(this.realtimeMinutes?.length === 0 && this.summary){
      this.realtimeMinutes.push({
        agenda: summaryAgenda,
        type: MINUTE.summary,
        markdown: this.summary
      })
    }

    if (data.fullAudioUrl) {
      this.fullAudioUrl = data.fullAudioUrl;
    }

    makeAutoObservable(this)
  }

  setData = (data: any) => {
    Object.assign(this, data)

    if (data.beginAt) {
      this.beginAt = timestampToDate(data.beginAt)
    }
    if (data.endAt) {
      this.endAt = timestampToDate(data.endAt)
    }
    if (data.createdAt) {
      const createdAt = new Date(data.createdAt.seconds * 1000)
      this.createdAt = formatDate(createdAt)
    }
    if (data.updatedAt) {
      const updatedAt = new Date(data.updatedAt.seconds * 1000)
      this.updatedAt = formatDate(updatedAt)
    }
    if(this.summary){
      if(!this.realtimeMinutes){
        this.realtimeMinutes = [];
      }
      const index = this.realtimeMinutes?.findIndex(r => r.type === MINUTE.summary);
      if(index === -1){
        this.realtimeMinutes.push({
          agenda: summaryAgenda,
          type: MINUTE.summary,
          markdown: this.summary
        })
      }else if(this.realtimeMinutes && this.realtimeMinutes[index]){
        this.realtimeMinutes[index].markdown = this.summary;
      }
    }
  }

  fetchMessages = async (fullyFetch: boolean = false) => {
    if (this.fetching || this.fullyFetched) {
      return
    }
    if (this.messages != null && !fullyFetch) {
      return
    }

    if (this.owner) {
      this.fetching = true
      let messagesSnap = null

      // TODO need revisit
      if (fullyFetch) {
        messagesSnap = await getMeetingMessages(this.owner, this.id)
      } else {
        messagesSnap = await getMeetingMessages(this.owner, this.id, PREVIEW_MESSAGE_AMOUNT)
      }

      // collect messages
      const messages: ChatMessage[] = []
      // count user
      const users: { [userId: string]: boolean } = {}
      // collect languages
      const lang: { [language: string]: boolean } = {}

      messagesSnap.docs.forEach((doc) => {
        const msgData = doc.data() as ChatMessage
        if (msgData.createdAt) {
          msgData.createdAt = timestampToDate(msgData.createdAt)
        }
        if (msgData.updatedAt) {
          msgData.updatedAt = timestampToDate(msgData.updatedAt)
        }

        messages.push({
          ...msgData,
          id: doc.id,
        })

        users[msgData.userId] = true
        Object.keys(msgData.messages || {}).forEach((l) => lang[l] = true)
      })

      this.setData({
        messages: messages,
        users: Object.keys(users),
        languages: Object.keys(lang),
        // status
        fetching: false,
        fullyFetched: fullyFetch || (messages.length < PREVIEW_MESSAGE_AMOUNT)
      })
    }
  }

  updateMessageText = async (message: ChatMessage) => {
    await updateMeetingMessageText(this.owner, this.id, message.id, message.messages);
    await this.fetchMessages(true);
  }

  deleteMessage = async (message_id: string) => {
    await deleteMeetingMessage(this.owner, this.id, message_id);
    await this.fetchMessages(true);
  }
}
