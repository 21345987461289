import i18next from "i18next";
import EventDispatcher, { type ObjectChangeListener } from '../util/EventDispatcher'
import { type PopupOptions } from './Popup'

export const BUTTON_MODES = {
  OK_CANCEL: 'okCancel',
  OK: 'ok',
  CANCEL: 'cancel',
  NONE: 'none',
}

export const SIZE_MODE = {
  XLARGE: 'modal-xl',
  LARGE: 'modal-lg',
  MEDIUM: '',
  SMALL: 'modal-sm',
}

export const POPUP_DEFAULT: PopupOptions = {
  title: '',
  content: '',
  btnMode: BUTTON_MODES.OK_CANCEL,
  /** @type {boolean | Promise | undefined} */
  contentReady: undefined,
  resolve: () => { },
  reject: () => { },
  show: false,
  okText: 'OK',
  cancelText: 'cancel', // Change the characters depending on language settings
  okAction: undefined,
  size: SIZE_MODE.MEDIUM,
  staticBackdrop: true,
  center: true,
}

export const ACTIONS = {
  SHOW: 'show',
  HIDE: 'hide',
  MANUAL_UPDATE: 'manualUpdate',
}

const POPUP_CHANNEL = 'popup'
const dispatcher = new EventDispatcher()

export const addPopupListener = (listener: ObjectChangeListener<never, PopupOptions, any>) => {
  return dispatcher.addListener(listener, POPUP_CHANNEL)
}

export const show = (options: PopupOptions) => {
  POPUP_DEFAULT.cancelText = i18next.t('キャンセル')
  return new Promise<boolean>((resolve, reject) => {
    dispatcher.dispatch({
      action: ACTIONS.SHOW,
      data: {
        ...POPUP_DEFAULT,
        ...options,
        resolve,
        reject,
      },
    }, POPUP_CHANNEL)
  })
}

/**
 * Manually update any state of current popup.
 */
export const updateState = (state: PopupOptions) => {
  dispatcher.dispatch({
    action: ACTIONS.MANUAL_UPDATE,
    data: state,
  }, POPUP_CHANNEL)
}

export const hide = (result = false) => {
  dispatcher.dispatch({
    action: ACTIONS.HIDE,
    data: result,
  }, POPUP_CHANNEL)
}
